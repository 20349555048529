<template>
  <div :class="['input-date-time', { error: isValidate }]">
    <label v-if="textFloat">
      {{ textFloat }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <datetime
      class="date-time-input theme-main"
      :type="type"
      :format="format"
      :placeholder="placeholder"
      :name="name"
      v-bind:value="value"
      :required="required"
      :size="size"
      @input="changeInput"
      :maxDatetime="currentDate"
    ></datetime>

    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        กรุณากรอกข้อมูลให้ครบถ้วน
      </span>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";

export default {
  props: {
    textFloat: {
      required: false,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    isTime: {
      required: false,
      type: Boolean
    },
    required: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: String
    },
    v: {
      required: false,
      type: Object
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    size: {
      required: false,
      type: String
    },
    maxDatetime: {
      required: false,
      type: Date
    }
  },
  template: "...",
  components: {
    datetime: Datetime
  },
  data() {
    return {
      format: "dd/MM/yyyy",
      currentDate: new Date().toISOString().slice(0, 10)
    };
  },
  mounted() {
    if (this.type == "date") {
      this.format = "dd/MM/yyyy";
    } else if (this.type == "datetime") {
      this.format = "dd/MM/yyyy HH:mm";
    }

    //this.currentDate = this.$moment(this.currentDate).format("YYYY-MM-DD");
  },
  methods: {
    changeInput: async function(val) {
      var result = await this.$moment(val)
        .tz("Asia/Bangkok")
        .format("YYYY-MM-DDT00:00:00");
      this.$emit("input", result);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-date-time {
  margin-bottom: 15px;
  position: relative;
}
.input-date-time.error {
  border-color: #ff0000 !important;
}
.input-date-time > label {
  color: #575757;
  font-size: 14px;
  margin-bottom: 2px;
}
::v-deep .date-time-input > .vdatetime-input {
  color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 5px 10px;
  width: 100% !important;
  cursor: pointer;
}
::v-deep .date-time-input > .vdatetime-input[size="lg"] {
  height: 45px;
}
::v-deep .date-time-input > .vdatetime-input::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
::v-deep .date-time-input > .vdatetime-input:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::v-deep .date-time-input > .vdatetime-input::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
::v-deep .theme-main .vdatetime-popup__header,
::v-deep .theme-main .vdatetime-calendar__month__day--selected > span > span,
::v-deep
  .theme-main
  .vdatetime-calendar__month__day--selected:hover
  > span
  > span {
  background: #3f51b5 !important;
}
::v-deep .theme-main .vdatetime-year-picker__item--selected,
::v-deep .theme-main .vdatetime-time-picker__item--selected,
::v-deep .theme-main .vdatetime-popup__actions__button {
  color: #3f51b5 !important;
}
::v-deep .vdatetime-overlay {
  z-index: 1201;
}
::v-deep .vdatetime-popup {
  z-index: 1202;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.input-custom.error > input {
  border-color: #ff0000 !important;
}
@media (max-width: 767.98px) {
  /* .input-date-time {
      margin-top: 0;
    } */
  .input-date-time > label {
    font-size: 15px;
  }
}
</style>
