<template>
  <div class="chat-box-mention-item" @click="selectMention(staff)">
    <div
      v-if="staff.imageUrl"
      class="rounded-circle user_img_display img-admin-size mb-1"
      v-bind:style="{
        backgroundImage: 'url(' + staff.imageUrl + ')'
      }"
    ></div>
    <div
      v-else
      class="rounded-circle img-admin img-admin-size mb-1 mr-1 mt-1"
      v-bind:style="{
        backgroundColor: getColorFromName(staff.firstname),
        color: 'white'
      }"
    >
      <span>{{ formatName(staff.firstname) }}</span>
    </div>

    <p>{{ staff.firstname }} {{ staff.lastname }}</p>
    <p>{{ staff.email }}</p>
  </div>
</template>

<script>
export default {
  name: "ChatBoxMention",
  props: {
    staff: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    selectMention(val) {
      this.$emit("handleClickMention", val);
    },
    formatName(val) {
      return val
        ? val
            .trim()
            .charAt(0)
            .toUpperCase()
        : "";
    },
    getColorFromName(name) {
      if (!name) return "#CCCCCC"; // กรณีไม่มีชื่อ
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
      }
      return color;
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-box-mention-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  padding: 5px 10px;

  p {
    margin: 0;
  }
  p:last-of-type {
    margin-left: auto;
  }
}
</style>
