<template>
  <div class="chat-hover">
    <p>Overdue by {{ formattedTime }}</p>
    <hr />
    <p>Last Response by {{ lastResponseText }}</p>
    <p>{{ lastMessageUpdateTimeString }}</p>
  </div>
</template>

<script>
export default {
  name: "ChatDueToolTip",
  props: {
    formattedTime: {
      required: true,
      type: String
    },
    lastMessageUpdateTimeString: {
      required: true,
      type: String
    },
    lastResponseText: {
      required: true,
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.chat-hover {
  text-align: right;

  hr {
    background-color: white;
    margin-bottom: 0;
  }
  p {
    font-size: small;
    margin-bottom: 0;
  }
}
</style>
