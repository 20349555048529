export function formatLargestTimeUnit(time, fullFormat = false) {
  if (time >= 1440) {
    const days = Math.floor(time / 1440); // 1 วัน = 1440 นาที
    return `${days}${fullFormat ? " day" : "d"}`;
  } else if (time >= 60) {
    const hours = Math.floor(time / 60); // 1 ชั่วโมง = 60 นาที
    return `${hours}${fullFormat ? " hour" : "h"}`;
  } else {
    return `${time}${fullFormat ? " minute" : "m"}`;
  }
}
